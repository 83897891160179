import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker, Polygon, DrawingManager } from "@react-google-maps/api";
import { auth } from "../firebase-config";
import { signOut } from "firebase/auth";

const containerStyle = {
  width: "100%",
  height: "90vh",
};

const center = {
  lat: 40.7128, // Example: New York City latitude
  lng: -74.006, // Example: New York City longitude
};

const mapOptions = {
  fullscreenControl: true,
  mapTypeControl: true,
  streetViewControl: false,
  zoomControl: true,
};

const polygonOptions = {
  fillColor: "#00FF00",
  fillOpacity: 0.3,
  strokeColor: "#00FF00",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  editable: true,
};

const MapView = () => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [infoMessage, setInfoMessage] = useState("");

  // Handle map click to place a marker
  const handleMapClick = (event) => {
    const position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(position);
    setInfoMessage(`Marker placed at: ${position.lat.toFixed(4)}, ${position.lng.toFixed(4)}`);
  };

  // Handle polygon completion
  const handlePolygonComplete = (polygon) => {
    const paths = polygon
      .getPath()
      .getArray()
      .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

    setPolygons([...polygons, paths]);
    setInfoMessage("Polygon drawn successfully!");

    // Cleanup the completed polygon
    polygon.setMap(null);
  };

  // Log out user
  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert("Logged out successfully!");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBnzIkoRjhJKVoncEjfNd7j6FGCViOxJHA" libraries={["drawing"]}>
      <div style={{ padding: "10px" }}>
        <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>Interactive Map</h1>
        <p style={{ color: "#555", marginBottom: "10px" }}>{infoMessage}</p>
        <button
          onClick={handleLogout}
          style={{
            marginRight: "10px",
            padding: "8px 16px",
            backgroundColor: "#DC3545",
            color: "#FFF",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Logout
        </button>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerPosition || center}
          zoom={10}
          options={mapOptions}
          onClick={handleMapClick} // Detect map clicks
        >
          {/* Marker */}
          {markerPosition && <Marker position={markerPosition} />}

          {/* Polygons */}
          {polygons.map((paths, index) => (
            <Polygon
              key={index}
              paths={paths}
              options={polygonOptions}
            />
          ))}

          {/* Drawing Manager */}
          <DrawingManager
            onPolygonComplete={handlePolygonComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                drawingModes: ["polygon"],
              },
              polygonOptions,
            }}
          />
        </GoogleMap>
        {/* Polygon Data Display */}
        <div style={{ marginTop: "20px" }}>
          <h2>Polygon Data</h2>
          <ul>
            {polygons.map((paths, index) => (
              <li key={index}>
                Polygon {index + 1} - Points: {paths.length}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </LoadScript>
  );
};

export default MapView;
