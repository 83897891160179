import React, { useState, useEffect } from 'react';

const HomeDashboard = () => {
  const [welcomeMessage, setWelcomeMessage] = useState('');

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
      setWelcomeMessage('Good morning');
    } else if (hour < 18) {
      setWelcomeMessage('Good afternoon');
    } else {
      setWelcomeMessage('Good evening');
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-4">
          {welcomeMessage}, welcome to AeroSwath
        </h1>
        <p className="text-gray-700">Your aerial operation reporting hub.</p>
        <div className="mt-6">
          <ul className="space-y-4">
            <li>
              <a
                href="/map"
                className="text-blue-600 hover:underline font-medium"
              >
                Interactive Map
              </a>
            </li>
            <li>
              <a
                href="/upload"
                className="text-blue-600 hover:underline font-medium"
              >
                File Uploads
              </a>
            </li>
            <li>
              <a
                href="/settings"
                className="text-blue-600 hover:underline font-medium"
              >
                User Settings
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeDashboard;
