import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeDashboard from './pages/HomeDashboard'; // Home dashboard page
import MapView from './pages/MapView'; // Interactive map view

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the home dashboard */}
        <Route path="/" element={<HomeDashboard />} />

        {/* Route for the interactive map */}
        <Route path="/map" element={<MapView />} />
      </Routes>
    </Router>
  );
}

export default App; // Ensure the default export is included
// Re-triggering deployment
